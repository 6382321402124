<template>
  <div class="container">
    <div class="notFoundText">Oops. Page Not Found. :(</div>
    <div class="backButton" @click="onBackButtonClick">Go Back</div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  methods: {
    onBackButtonClick() {
      this.$router.go(-1);
    }
  },
  metaInfo: {
    title: 'Page Not Found.'
  }
};
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--u-font-family-Gilroy);

  .notFoundText {
    margin-bottom: 30px;
    font-size: 2em;
  }

  .backButton {
    padding: 10px 30px;

    color: #fff;
    background-color: #aebcc4;

    border-radius: 8px;

    cursor: pointer;

    &:hover {
      color: #fff;
      background-color: #94a6b0;
    }
  }
}
</style>
